import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"
import objectFitImages from "object-fit-images"
objectFitImages();

var parentUrl = (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;

if (parentUrl.indexOf('poloniapalace.com/pl') !== -1) {
    if (location.pathname == '/index-en.html') {
        location.href='./';
    }
} else {
    if (location.pathname != '/index-en.html') {
        location.href = 'index-en.html';
    }
}

if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest =
        function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i,
                el = this;
            do {
                i = matches.length;
                while (--i >= 0 && matches.item(i) !== el) { };
            } while ((i < 0) && (el = el.parentElement));
            return el;
        };
}

// Select rooom
const roomSelect = document.getElementById('roomSelect');
if (roomSelect) {
    roomSelect.onchange = () => {
        const value = roomSelect.options[roomSelect.selectedIndex].value;
        Array.from(document.getElementsByClassName('room-item')).forEach(node => node.classList.add('desktop'));
        document.getElementById(value).classList.remove('desktop');
        document.getElementById('roomImages').setAttribute("href", document.getElementById(value).querySelector('a').getAttribute("href"));
    };
}


// Popover on click
const photoMapper = photo => `<div class="photo"><img src="./build/images/${photo}" /></div>`
const getOffset = node => {
    return node.tagName === 'BODY' ? {top: 0, left: 0} : {
        top: getOffset(node.offsetParent).top + node.offsetTop,
        left: getOffset(node.offsetParent).left + node.offsetLeft
    }
};
const tnsCollection = {
    containers : {},
    destroy: key => tnsCollection.containers[key] && tnsCollection.containers[key].destroy(),
    init: (key, config) => tnsCollection.containers[key] = tnsCollection.containers[key] ? tnsCollection.containers[key].rebuild() : tns(config)
};
const tnsDestroy = key => tnsCollection.destroy(key);
const tnsInit = (key, config) => tnsCollection.init(key, config);

const showRoomPopover = item => {
    const popover = document.getElementsByClassName('room-popover')[0];
    const photos = item.photos.length ? item.photos.map(photoMapper).join("") : '';
    const offset = getOffset(item.node);

    tnsDestroy('room-popover');
    popover.querySelector('.room-popover__content').innerHTML = item.content;
    popover.querySelector('.room-popover__slider').innerHTML = photos;

    tnsInit('room-popover', {
        container: '.room-popover .room-popover__slider',
        mouseDrag: true,
        nav: false,
        autoHeight: true
    });

    const viewportWidth = document.body.clientWidth;
    const viewportHeight = document.body.clientHeight;
    popover.style.display = 'block';
    if (offset.top + popover.clientHeight > viewportHeight) {
        popover.style.top = offset.top - popover.clientHeight + 'px';
    } else {
        popover.style.top = offset.top + item.node.clientHeight + 'px';
    }
    popover.style.left = offset.left - (popover.clientWidth - item.node.clientWidth)/2 + 'px';
};
const hideRoomPopover = () => {
    const popover = document.getElementsByClassName('room-popover')[0];
    popover.style.display = 'none';
}
Array.from(document.getElementsByClassName('room-popover__close')).forEach(node => node.onclick = hideRoomPopover)
Array.from(document.getElementsByClassName('table__cell__value'))
    .filter(node => /\d+[^\d]+\d+/.test(node.innerText.trim()))
    .map(node => {
        const roomName = node.closest('.room-item').querySelector('.roomtbl__name').innerText;
        const match = node.innerText.trim().match(/(\d+)[^\d]+(\d+)/);
        const header = node.closest('.table__cell').querySelector('.table__cell__header span').innerHTML.replace(/^([^<]+)<.*/, '$1');
        return {
            node : node,
            content : `Sala: ${roomName}<br>Układ: ${header} ${match[0]} do 170 osób`,
            photos: node.dataset.photos.length > 0 ? node.dataset.photos.split(",") : []
        }
    })
    // popover tylko jestli sa obrazki
    .filter(item => item.photos.length)
    .forEach(item => {
            item.node.style.cursor = 'pointer';
            item.node.style.backgroundColor = 'rgba(0,0,0,.02)'
            item.node.onclick = () => showRoomPopover(item);
    });

// Photo gallery
const galleryReducer = (keep, node) => {
    keep.push(...(node.dataset.photos.length > 0 ? node.dataset.photos.split(",") : []));
    return keep;
}
const fillGallery = selector => {
    const photos = Array.from(document.querySelector(selector).querySelectorAll('[data-photos]'))
                        .reduce(galleryReducer, [])
                        .map(photoMapper)
                        .join("");
    tnsDestroy('room-gallery')
    document.querySelector('.room-gallery__slider').innerHTML = photos;
    tnsInit('room-gallery', {
        container: '.room-gallery .room-gallery__slider',
        mouseDrag: true,
        nav: false,
        autoHeight: true
    });
}
const showGallery = e => {
    e.preventDefault();
    fillGallery(e.currentTarget.getAttribute("href"));
    document.querySelector('.room-gallery').style.display = 'block';
};
const hideGallery = () => document.querySelector('.room-gallery').style.display = 'none';
Array.from(document.getElementsByClassName('room-gallery__close')).forEach(node => node.onclick = hideGallery)
Array.from(document.querySelectorAll('.roomtbl__images a')).forEach(node => node.onclick = showGallery);


// Details
const showRoomDetails = selector => e => {
    const node = document.querySelector(selector);
    document.body.appendChild(node);
    node.style.display = 'block';
}
const hideRoomDetails = (e) => e.target.closest('.room-details').style.display = 'none';
Array.from(document.getElementsByClassName('room-details__close')).forEach(node => {
    node.onclick = hideRoomDetails;
})
Array.from(document.querySelectorAll('[data-details]')).forEach(node => {
    node.style.cursor = 'pointer';
    node.onclick = showRoomDetails(node.dataset.details);
})

document.querySelectorAll('.room-details-img-link, .spatial-view-slide-photo').forEach(node => {
    node.onclick = showGallery;
});
document.querySelectorAll('.room-details-magnific').forEach(node => {
    node.onclick = function () {
        node.classList.remove('active');
    };
});
document.querySelector('.btn-spatial-view').onclick = function () {
    document.querySelector('.spatial-view').classList.add('active');
};
document.querySelector('.spatial-view-close').onclick = function () {
    document.querySelector('.spatial-view').classList.remove('active');
};
document.querySelectorAll('.spatial-view-nav-link').forEach(node => {
    node.onclick = function (event) {
        event.preventDefault();
        document.querySelectorAll('.spatial-view-nav-link, .spatial-view-tab').forEach(node => node.classList.remove('active'));
        document.querySelector(node.hash).classList.add('active');
        node.classList.add('active');
    };
});
document.querySelectorAll('.spatial-view-slider').forEach(function(node) {
    var slider = {
        slider: node,
        inner: node.querySelector('.spatial-view-slider-inner'),
        slide: 0,
        total: node.querySelectorAll('.spatial-view-slide').length,
        slideHeight: 240,
        slides: 2,
        rewind: true,
        update: function() {
            this.inner.style.transform = `translateY(${-this.slide * this.slideHeight}px)`;
        },
        goto: function(index) {
            if (index > this.total - this.slides) index = this.total - this.slides;
            if (index < 0) index = 0;
            this.slide = index;
            this.update();
        },
        next: function () {
            if (this.rewind && this.slide + 1 > this.total - this.slides) {
                this.goto(0);
            } else {
                this.goto(this.slide + 1);
            }
        },
        prev: function () {
            if (this.rewind && this.slide - 1 < 0) {
                this.goto(this.total - this.slides);
            } else {
                this.goto(this.slide - 1);
            }
        }
    };
    node.closest('.spatial-view-slider-container').querySelector('.spatial-view-slider-prev').onclick = () => slider.prev();
    node.closest('.spatial-view-slider-container').querySelector('.spatial-view-slider-next').onclick = () => slider.next();
});
document.querySelectorAll('.spatial-view-slide-photo').forEach(function (node) {
    var elements = node.dataset.room.split(',');
    node.onmouseenter = function() {
        elements.forEach(index => {
            document.querySelector(`.spatial-view-area [data-details="#roomDetails${index}"]`).classList.add('active');
        });
    };
    node.onmouseleave = function () {
        elements.forEach(index => {
            document.querySelector(`.spatial-view-area [data-details="#roomDetails${index}"]`).classList.remove('active');
        });
    };
});